<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A solution contains
        <number-value :value="mass1" unit="\text{g}" />
        of ethanol,
        <chemical-latex content="C2H5OH," />
        and
        <number-value :value="mass2" unit="\text{g}" />
        of water at 25<sup>o</sup>C.
      </p>

      <p class="mb-3">a) What is the mole fraction of ethanol in this solution?</p>

      <calculation-input
        v-model="inputs.XEtOH"
        class="mb-5"
        prepend-text="$\text{X}_\text{EtOH}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">b) What is the mole fraction of water in this solution?</p>

      <calculation-input
        v-model="inputs.XH2O"
        class="mb-5"
        prepend-text="$\text{X}_\ce{H2O}:$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        c) If the vapour pressure of pure water at 25<sup>o</sup>C is
        <stemble-latex content="$0.0313\,\text{atm}$" />
        and the vapour pressure of pure ethanol at 25<sup>o</sup>C is
        <stemble-latex content="$0.07725\,\text{atm,}$" />
        determine the vapour pressure of the solution at this temperature.
      </p>

      <calculation-input
        v-model="inputs.vpSoln"
        prepend-text="$\text{P}_\text{soln}:$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question414',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        XEtOH: null,
        XH2O: null,
        vpSoln: null,
      },
    };
  },
  computed: {
    mass1() {
      return this.taskState.getValueBySymbol('mass1').content;
    },
    mass2() {
      return this.taskState.getValueBySymbol('mass2').content;
    },
  },
};
</script>
